.headerImage{
    height: 200%;
    padding: 5px;
    width: auto;
}
#playstoreImage {
    width:100px;
    margin-top:-5px;
    border:3px solid white;
    border-radius:10px;
}
