// @import 'https://fonts.googleapis.com/css?family=Noto+Sans'

// Font variables
$main-font: 'Noto Sans', sans-serif;

// Color variables
$darkblue: rgba(40, 75, 99, 0.8);
$fadedwhite: rgba(255,255,255,.7);
$accentcolor: #58a4b0;
$fbcolor: #3A5A98;
$twcolor: #55ACEE;

body {
  background: radial-gradient(ellipse farthest-corner at center top, #d8dbe2 0%, #a9bcd0 100%);
  height: 100vh;
  font-family: $main-font;
  background: url('https://hd.unsplash.com/photo-1435777940218-be0b632d06db') no-repeat center center fixed; }

// App style
.Modal {
  margin-top: 3%;
  padding-top: 2%;
  width: 30vw;
  height: 70vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #E8E8E8;
  background-size: cover;
  border-radius: 5px;
  // Lost password style
  & a {
    display: block;
    font-size: 12px;
    text-align: center;
    text-decoration: none;
    margin: 4px 0 10px 0;
    margin: 10px;
    &:hover {
      text-decoration: underline; } }
  & p {
    display: block;
    font-size: 12px;
    text-align: center; }

  & button {
    margin-top: 10%;
    width: 20vw; } }
// Input
.Input {
  // display: flex
  // Hack to be able to have a label pseudo element before the input field
  flex-direction: row-reverse;
  width: 20vw;
  align-items: center;
  border-bottom: 1px solid #58a4b0;
  margin: 10px auto;
  margin-top: 5%;
  margin-left: 10%;
  & input {
    border: 0;
    background: transparent;
    padding: 2px 10px;
    margin: 18px 4px 0 4px;
    color: black;
    font-family: $main-font;
    font-size: 20px;

    &:required {
      box-shadow: none; }

    // Animation trigger for label::before
    &:focus ~ label {
      opacity: 1; }

    // label::before icons
    &[type='text'] {
      ~ label {
        &::before {
          content: "\f007"; } } }
    &[type='password'] {
      ~ label {
        &::before {
          content: "\f023"; } } } } }

// Label
label {
  font-family: FontAwesome;
  color: white;
  font-size: 14px;
  margin-top: 17px;
  transition: opacity .3s ease-in-out;
  // pseudo element before input field (see hack above)
  &::before {
    padding: 5px 5px 0 0;
    opacity: 0.5; } }

// Buttons

// Sign in button
form {
  & button {
    position: relative;
    margin: 15px 0 5px 0;
    left: 50%;
    transform: translateX(-50%);
    background: $accentcolor;
    border: none;
    border-radius: 5px;
    padding: 10px;
    color: white;
    width: 85%;
    font-family: $main-font;
    font-size: 14px;
    cursor: pointer; } }

// Social login buttons
.social-signin {
  text-align: center;
  & button {
    width: 40%;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;
    & i {
      font-size: 20px;
      color: white; }
    &.fb {
      background: $fbcolor;
      margin-right: 13px; }
    &.tw {
      background: $twcolor; } } }
// modal back button
.bringitback {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  background: $accentcolor;
  border: none;
  border-radius: 5px;
  padding: 10px;
  color: white;
  width: 200px;
  font-family: $main-font;
  font-size: 14px;
  cursor: pointer; }

// Fake logo
.logo {
  margin-bottom: 3%;
  text-align: center;
  color: white;
  & img {
    width: 8%;
    height: 8%; }
  & i {
    display: block;
    padding: 20px 0 0 0;
    font-size: 70px;
    transform: translateX(-5px); }
  & span {
    color: black;
    text-transform: uppercase; } }


// React CSS animations

// On enter
.animation-enter {
  opacity: .01;

  &.animation-enter-active {
    opacity: 1;
    transition: opacity .6s ease, margin .5s ease; } }

// On leave
.animation-leave {
  margin-top: 0px;
  opacity: 1;
  &.animation-leave-active {
    margin-top: -30px;
    opacity: .01;
    transition: opacity .2s ease; } }

// On mount
.animation-appear {
  opacity: 0.01; }
.animation-appear.animation-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in; }
