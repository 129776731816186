.appview1-container{
    height: 95vh;
    width: 100%;
    overflow: hidden;
}
.infoContainer{
  float: left;
  width: 70%;
  margin-top: 10%; 
  height: 90%;
}
.noDisplay{
  display: none !important;
}

.phoneScreenContainer{
  width:30%;
  height: 100%;
  float: left;

}
.featuresText{
  font-size:18px;
  padding:10px;
  font-weight: 800;
}
.botTextContainer {

  overflow:hidden;
  margin-bottom: 10%;
}
.bubbleContainer{
  margin-left: -5%;
}
.botText {
  width:100%;
  font-family: "Trebuchet MS","Helvetica",sans-serif;
    font-size:32px; color:#02307B;
}
.phoneShow{display:inline-block;}
.text-center{
  text-align: center;
}
.phone-frame-image,.image-in-frame{
  position: absolute;
  height: 80vh;
}
.phone-frame-div{
  margin-left: 15%;
}
.featurePic { 
  cursor:pointer; border-radius:50%; border:1px solid black;
}
.mobile-1--content {
  position: relative;
  top:40px; 
}

.is-table {
  display: table; 
}
.full-wh {
  height: 100%;
  width: 100%; 
}
.v-center{
  display: table-cell;
  vertical-align: middle;
  float: none;
}
.phoneScreen1 {
  background-color: white;
}