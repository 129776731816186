
.appview2-container{
    height: 95vh;
    width: 100%;
    position: relative;
  }
  .feature-container{
    background:linear-gradient(to right,#E6E7E9, #A4A5A9);
    display: inline-block;
    height: 80vh;
    width: 100%;
    padding: 0!important;
  
  }
  
  .feature-title{
    font-family: "Trebuchet MS","Helvetica",sans-serif;
    font-size: 5rem;
    line-height: 110%;
    margin: 10px 0;
    font-style: inherit;
    color: #ffffff;
  }
  .feature-div{
    margin-top: 5%;
    position: relative;
    margin-left: 20%;
  }
  .phone-frame-image,.image-in-frame{
    position: absolute;
    height: 80vh;
  }
  .image-in-frame{
    z-index: 10;
    height: 57vh;
    margin-top: 5.5%;
    margin-left: 1.5%;
  }
  .phone-frame-div{
    margin-left: 15%;
  }
  .featureText{
    margin-top: 5%;
    margin-right: 8%;
    color:black;
    font-weight: bold;
    font-size: 20px;
  }
  .feature-info-container{
    
    margin-left:  auto;
    height: 70vh;
    float: left;
    width: 60%;
  }
  .feature-image-container{
    height: 70vh;
    width:40%;
    float: left;
  }
  .feature-tab-container{
    position: absolute;
    height: 20vh;
    width: 100%;
    bottom: 0;
    overflow: hidden;
  }
  ul{
    list-style-type: none;
  }
  .innews-container{
    height: 30vh;
    background-color: pink;
  }
  .press--band .press-col {
    display: table-cell;
    vertical-align: middle;
    text-align: center; 
    overflow: hidden;
  }
  .press--band .press-col img {
    width: 65%; 
  }
  .features-tab-list li{
    margin-top: 1%;
  }
  .row-feature {  
  
    height: 20vh;
    bottom: 0;
    width: 120%;
    background: #444444;
    margin-left: -15px;
    margin-right: -15px; 
  }
      
  ._comp--image {
    width: 40px;
    height: 40px;
    display: inline-block; 
  }
  ._comp--image img, .features-tab-list ._comp--image svg {
    width: 100%;
    height: 100%; 
  }
  ._comp--image svg * {
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s; 
  }
            
  .features-tab-list a:hover ._comp--image svg * {
    stroke: #00A3DB; 
  }
              
  .features-tab-list li {
    width: 25%;
    float: left;
    text-align: center;
    border-right: 1px solid #ffffff;
    padding: 10px 0; 
  }
  .features-tab-list li:last-child {
    border-right: 0; 
  }
                  
  ._container__mobile-2 {
    height: 80%;
    padding: 0; 
  }
  ._container__mobile-2--tabs {
    height: 20%; 
  }
  ._container__mobile-2--tabs .features-tab-list li {
    margin: 0; 
  }
  .mobile-2--content .icon-wrapper img {
    width: 85px;
    height: 85px; 
  } 
                          
  ._container__mobile-2 {
    padding: 50px 0; }
  ._container__mobile-2--tabs .features-tab-list li {
    margin: 25px 0; }
  
  .full-h{
    margin: auto;
    width:100%;
    z-index: 1;
  
    /* opacity: 0.3; */
  }        
  .container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px; }
  .container:before,
  .container:after {
    content: " ";
    display: table; }
  .container:after {
    clear: both; }
    
  @media (min-width: 768px) {
  .container {
    width: 750px; } 
  }
  @media (min-width: 992px) {
  .container {
    width: 970px; } }
    
  @media (min-width: 1200px) {
  .container {
    width: 1170px; } }
  .mobile-2--tabs {
    display: table; }
  .feature-image img {
    width: 50%;
    margin-bottom: 15px; 
  }
  .features-panel {
    width: 50%;
    display: block;
    margin-left: 20%;
    text-align: center;
    margin-bottom: 50px; 
  }
  @media screen and (min-width: 768px) {
    .features-panel {
      /* display: none; */
      text-align: left;
      margin-bottom: 0px;
    } 
  }
  .features-panel.active {
    display: block; 
  }
  .featureText {
    color:black;
  }
    
  
  
  
  
  
  
  
  
  /* html, body {
  
  width: 100%;
  height: 100%;
  color: $color-white;
  background: $color-black;
  }
    
    .marquee {
    overflow: hidden;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    background: red;
  }
  
  .child {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: blue;
  // margin: auto;
  transform: translate3d(0, 0, 0);
  
  img {
  display: inline-block;
  width: 320px;
  height: auto;
  margin: auto;
  } 
  } */