.backgroundWrapper{
    height: 90vh;
    background-repeat:  no-repeat;
    width: 100%;
    background-size:  100% 100%;
    background-position:center center;

    background-image: url('./images/Thub548.jpg');
}

.careerText{
    padding-top: 380px;
    color: white;
    font-family: "adelle-sans", "Avenir", "Avenir Next", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 56px
}
.text-center{
    text-align: center;

}
#careerMain {
    height: 130vh;
    background-position:center center;
    background-repeat:  no-repeat;
    background-size:  100% 100%;
    background-image: url('./images/careerbottom.jpg');
}
.whyTinkerix h1 {
    margin:30px;
  }
  #whyTinkerix p{

    line-height: 180%;
    padding-top: 20px;
    padding-bottom:20px;
    font-size: 2rem;
    font-family: "adelle-sans", "Avenir", "Avenir Next", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.careeraccordion {
    width: 40%; 
    height: 100%;
    margin-top: 10%;
    text-align:center;
     border-radius: 20px 2px 20px 2px;
    background-color:white;
    transition: 1s;
  
}
