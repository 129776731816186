

.appscreens-frame {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: auto;
    -webkit-transform: translateY(16%);
    -moz-transform: translateY(16%);
    -ms-transform: translateY(16%);
    -o-transform: translateY(16%);
    transform: translateY(16%); }
    html.touch:not(.mousePresent) .appscreens-frame {
      -webkit-transition: all 0.4s ease 0s;
      -o-transition: all 0.4s ease 0s;
      transition: all 0.4s ease 0s; }
  
  /* ::Show phone based on orientation */
  /* ------------------------------------------------------------ */
  html.touch:not(.mousePresent) .appscreens-frame {
    -webkit-transform: translateY(16%) !important;
    -moz-transform: translateY(16%) !important;
    -ms-transform: translateY(16%) !important;
    -o-transform: translateY(16%) !important;
    transform: translateY(16%) !important; }
    html.touch:not(.mousePresent) .appscreens-frame.scrollDown {
      -webkit-transform: translateY(105%) !important;
      -moz-transform: translateY(105%) !important;
      -ms-transform: translateY(105%) !important;
      -o-transform: translateY(105%) !important;
      transform: translateY(105%) !important; }
  
  @media screen and (orientation: portrait) {
    html.touch:not(.mousePresent) .appscreens-frame {
      height: 50%;
      -webkit-transform: translateY(-50%) !important;
      -moz-transform: translateY(-50%) !important;
      -ms-transform: translateY(-50%) !important;
      -o-transform: translateY(-50%) !important;
      transform: translateY(-50%) !important; }
      html.touch:not(.mousePresent) .appscreens-frame.scrollDown {
        -webkit-transform: translateY(150%) !important;
        -moz-transform: translateY(150%) !important;
        -ms-transform: translateY(150%) !important;
        -o-transform: translateY(150%) !important;
        transform: translateY(150%) !important; } }
  
  .appscreens--phone {
    position: relative;
    height: 100%; }
  
  .appscreens--phoneFrame {
    height: 100%;
    text-align: center;
}
    .appscreens--phoneFrame img {
      width: auto;
      height: 100%; 
}
.appscreens--phone-1st-fold,
.appscreens--phone-2nd-fold {
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  overflow: hidden; 
}
.appscreens--phone-1st-fold {
    width: 86%;
    left: 7%; 
}
.ui-screen-wrapper {
    width: 100%;
    height: 100%;
    margin: 0;
    -webkit-transition: all 0.6s ease 0s;
    -o-transition: all 0.6s ease 0s;
    transition: all 0.6s ease 0s; 
}
.ui-screen {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 50%;
  top: 62%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }
.ui-screen img {
	height: 73%;
	width: auto;
}
.ui-slider .ui-slide:nth-child(1) {
  -webkit-transform: translate(-50%, -50%) translateZ(0);
  -moz-transform: translate(-50%, -50%) translateZ(0);
  -ms-transform: translate(-50%, -50%) translateZ(0);
  -o-transform: translate(-50%, -50%) translateZ(0);
  transform: translate(-50%, -50%) translateZ(0); 
}
.ui-slider .ui-slide:nth-child(2) {
  -webkit-transform: translate(-50%, -50%) translateZ(0);
  -moz-transform: translate(-50%, -50%) translateZ(0);
  -ms-transform: translate(-50%, -50%) translateZ(0);
  -o-transform: translate(-50%, -50%) translateZ(0);
  transform: translate(-50%, -50%) translateZ(0); 
}
.ui-slider .ui-slide:nth-child(3) {
  -webkit-transform: translate(-50%, -50%) translateZ(0);
  -moz-transform: translate(-50%, -50%) translateZ(0);
  -ms-transform: translate(-50%, -50%) translateZ(0);
  -o-transform: translate(-50%, -50%) translateZ(0);
  transform: translate(-50%, -50%) translateZ(0); 
}
  