.parent_container {
  width: 100vw;
 
  border: '1px solid red';
  overflow: hidden;

}
.child_container {
  float: left;
  height : 50;
  width: '50vw';
  
}

.icon_dimen{
  margin: 3%;
  color: #673AB7;
}
.alert {
  padding: 20px;
  background-color: #f44336;
  color: white;
  opacity: 1;
  transition: opacity 0.6s;
  margin-bottom: 15px;
}

.alert.warning {background-color: #ff9800;}
.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}