.testimonial-container{
  height: 60vh;
  background-color: black;
  overflow: hidden;
}

.userImage{
  padding-top:10px;
  padding-bottom:10px;
}

.userImage img {
    height:150px;
    width:150px;
    border-radius: 50%;
}

.userText{
    padding:5px 10px 10px 5px;
}
.swiper-wrapper{
  z-index : 10;
}
.swiper-container{
  overflow: auto !important;
}